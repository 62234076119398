import { defineComponent, ref, PropType } from 'vue'

import { ElButton } from 'element-plus'
import DsCreateDialog from './DsCreateDialog'


export interface actionButton {
  name: string
  title: string
  model: any
  action: (value: any, relations: any) => Promise<void>
  createLabel?: string
  cancelLabel?: string
}

export default defineComponent({
  name: 'ActionButton',
  components: { ElButton, DsCreateDialog },
  props: {
    properties: { type: Object as () => actionButton, required: true },
    relations: { type: Object, required: true }
  },
  setup(props) {
    const open = ref<boolean>(false)
    const modelValue = ref({})

    return () => (
      <>
        <ElButton size="medium" onClick={() => open.value = true}>
          {props.properties.name}
        </ElButton>
        <DsCreateDialog
          open={open.value}
          name={props.properties.name}
          title={props.properties.title}
          model={props.properties.model}
          value={modelValue.value}
          handleChange={(value) => modelValue.value = value}
          handleCreate={() => props.properties.action(modelValue.value, props.relations).then(() => { open.value = false })}
          relations={props.relations}
          onClose={() => (open.value = false)}
          onClosed={() => modelValue.value = {}}
          createButtonLabel={props.properties.createLabel}
          cancelButtonLabel={props.properties.cancelLabel}
        />
      </>
    )
  },
})
