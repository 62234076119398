import { defineComponent, PropType } from 'vue'
import { CaretBottom, CaretTop } from '@element-plus/icons'



export default defineComponent({
  name: 'DsSortIcon',
  components: {
    CaretBottom, CaretTop
  },
  props: {
    sortDirection: { type: String as PropType<'asc' | 'desc' | null>, required: false, default: null },
    height: { type: String, required: false, default: '10px' }
  },
  setup(props) {
    return () => (
      <span style={{ height: props.height, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <div style={{ color: props.sortDirection === 'asc' ? '#3e3e53' : '#c0c4cc', height: 'inherit' }}><CaretTop style='height: inherit' /></div>
        <div style={{ color: props.sortDirection === 'desc' ? '#3e3e53' : '#c0c4cc', height: 'inherit', marginTop: '-3px' }}><CaretBottom style='height: inherit' /></div>
      </span>
    )
  },
})