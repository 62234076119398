import { defineComponent } from 'vue'

import './DsTag.css'

export default defineComponent({
  name: 'DsTag',
  props: {
    inline: { type: Boolean, required: false, default: true }
  },
  setup(props, { slots }) {
    return () => <span class="ds-tag" >{slots.default && slots.default()}{!props.inline && <br />}</span>
  },
})
