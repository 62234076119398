import { defineComponent, PropType, computed, ref } from 'vue'
import { orderBy } from 'lodash'

import {
  ElButton,
  ElDropdown,
  ElDropdownItem,
  ElDropdownMenu,
  ElButtonGroup,
  ElIcon
} from 'element-plus'


import { MoreFilled, ArrowUp } from '@element-plus/icons'

import DsTag from './DsTag'
import DsPagination from './DsPagination'
import './DsTable.css'

import { Column, Action } from '@/components/DsTable'

import {
  Loading as LoadingIcon
} from '@element-plus/icons'


type Row = Record<string, any>

const isEmpty = (value: string | undefined | null | unknown[]): boolean => {
  return (
    value === '' || value === undefined || value === null || value.length === 0
  )
}

const getColumnValue = (row: Row, key: string) => {
  const path = key.split('.')
  if (path.length === 1) {
    return row[key]
  } else {
    let value = row
    for (const key of path) {
      value = value[key]
    }
    return value
  }
}


export default (<T extends Row>() =>
  defineComponent({
    name: 'DsTable',
    components: {
      DsTag,
      DsPagination,
      ElButtonGroup,
      ElDropdown,
      ElDropdownItem,
      ElDropdownMenu,
      ElIcon
    },
    props: {
      data: { type: Array as PropType<T[]>, required: true },
      columns: { type: Array as PropType<Column[]>, required: true },
      mainAction: { type: Object as PropType<Action>, required: false },
      secondaryActions: { type: Array as PropType<Action[]>, required: false },
      onRowClick: {
        type: Function as PropType<
          (row: T, column: Column, evt: MouseEvent) => void
        >,
      },
      groupByKey: { type: String, required: true, },
      rowKey: { type: String, required: false, default: 'id' },
      relations: { type: Object },
      loading: { type: Boolean, required: false, default: false },
    },
    setup(props) {
      const currentPage = ref(0)
      const pageLength = ref(25)
      const sortDirection = ref<'asc' | 'desc' | null>(null)
      const sortColumn = ref<string | null>(null)
      const mainActionLoading = ref(null)

      const dataLength = computed(() => props.data.length)
      const retractedGroup = ref<{ [key: string]: unknown }>({})

      const groupedData = computed(() => props.data.reduce((previous: any, currentValue: any) => {
        const groupName = currentValue[props.groupByKey] || 'Not grouped'
        previous[groupName] = previous[groupName] ? previous[groupName] : []
        previous[groupName].push(currentValue)
        return previous
      }, {}))

      const pageData = computed(() =>
        props.data.slice(
          currentPage.value * pageLength.value,
          (currentPage.value + 1) * pageLength.value
        )
      )

      const showActionsColumn = computed(() => {
        return (
          props.mainAction !== undefined ||
          (props.secondaryActions !== undefined &&
            props.secondaryActions.length > 0)
        )
      })

      const renderValue = (c: any, value: any) =>
        c.type === 'tag' && !isEmpty(value) ? (
          <DsTag>{value}</DsTag>
        ) : c.formatter !== undefined ? (
          c.formatter(value, props.relations)
        ) : (
          value
        )

      const retract = (groupName: string) => {
        if (retractedGroup.value[groupName]) {
          delete (retractedGroup.value[groupName])
        } else {
          retractedGroup.value[groupName] = true
        }
      }
      const rows = computed(() => {
        let data = pageData.value
        if (sortColumn.value) {
          data = orderBy(
            data,
            sortColumn.value || undefined,
            sortDirection.value || undefined
          )
        }

        return Object.entries(groupedData.value).map(group => {
          const [groupName, groupValues]: [string, Array<any> | unknown] = group
          return (<tbody class="group"><th colspan={
            showActionsColumn.value
              ? props.columns.length + 1
              : props.columns.length
          }><div class="rowHeadContainer">
              <div class="rowHead left" onClick={() => retract(groupName)}><ElIcon><ArrowUp class={{ arrow: true, rotate: groupName in retractedGroup.value || false }} /></ElIcon></div>
              <div class="rowHead right"><span class="title">{groupName}</span></div>
            </div>
          </th>
            {
              Array.isArray(groupValues) && groupValues.map((row) => (
                <tr key={row[props.rowKey]} style={{ display: groupName in retractedGroup.value ? 'none' : 'table-row' }}>
                  {props.columns.map((c) => {
                    const rowValue = getColumnValue(row, c.key)
                    return (
                      <td
                        key={`${row[props.rowKey]}-${c.key}`}
                        onClick={(event) =>
                          props.onRowClick && props.onRowClick(row, c, event)
                        }
                      >
                        {Array.isArray(rowValue)
                          ? rowValue.map((value: any) => renderValue(c, value))
                          : renderValue(c, rowValue)}
                      </td>
                    )
                  })}
                  {/* A transformer en Composant */}
                  {showActionsColumn.value && (
                    <td style="text-align: right">
                      <ElButtonGroup>
                        {props.mainAction ? (
                          <ElButton
                            size="small"
                            onClick={async () => {
                              mainActionLoading.value = row[props.rowKey]
                              const promise = props.mainAction && props.mainAction.callback(row)
                              if (promise) await promise
                              mainActionLoading.value = null
                            }}
                            icon={mainActionLoading.value === row[props.rowKey] ? <LoadingIcon style="animation: spin 2s linear infinite;" /> : props.mainAction.icon}
                          >
                            {props.mainAction && props.mainAction.label}
                          </ElButton>
                        ) : null}
                        {props.secondaryActions && (
                          <ElDropdown
                            trigger="click"
                            size="small"
                            onCommand={(index) =>
                              props.secondaryActions &&
                              props.secondaryActions[index]?.callback(row)
                            }
                            v-slots={{
                              default: () => (
                                <ElButton
                                  style={
                                    'width:28px;height:28px;padding:0px;' +
                                    (props.mainAction
                                      ? 'border-top-left-radius:0;border-bottom-left-radius:0'
                                      : '')
                                  }
                                  size="small"
                                  icon={
                                    <MoreFilled style="transform: rotate(90deg);width:auto" />
                                  }
                                ></ElButton>
                              ),
                              dropdown: () => (
                                <ElDropdownMenu>
                                  {props.secondaryActions &&
                                    props.secondaryActions.map((value, index) => (
                                      <ElDropdownItem
                                        command={index}
                                        icon={value.icon}
                                      >
                                        {value.label}
                                      </ElDropdownItem>
                                    ))}
                                </ElDropdownMenu>
                              ),
                            }}
                          ></ElDropdown>
                        )}
                      </ElButtonGroup>
                    </td>
                  )}
                </tr>
              ))
            }</tbody >)
        })
      })

      const renderSkeleton = () => {
        return [...new Array(4)].map((value, index) => (
          <tr key={index}>
            {props.columns.map(() => (
              <td class="loading-cell">
                <span class="loading-skeleton" />
              </td>
            ))}
            {showActionsColumn.value && (
              <td class="loading-cell">
                <span class="loading-skeleton" />
              </td>
            )}
          </tr>
        ))
      }
      const colHeadClick = (colKey: string) => {
        if (sortColumn.value === colKey) {
          switch (sortDirection.value) {
            case null:
              sortDirection.value = 'asc'
              break
            case 'asc':
              sortDirection.value = 'desc'
              break
            case 'desc':
              sortColumn.value = null
              sortDirection.value = null
              break
          }
        } else {
          sortColumn.value = colKey
          sortDirection.value = 'asc'
        }
      }

      return () => (
        <div class="dsflow-table-container">
          <div style="overflow-x: auto; flex: 1;">
            <table class="dsflow-table">
              <thead>
                <tr>
                  {props.columns.map((c) => (
                    <th
                      key={c.key}
                      onClick={() => (c.sortable ? colHeadClick(c.key) : null)}
                    >
                      {c.header}
                    </th>
                  ))}
                  {showActionsColumn.value && <th></th>}
                </tr>
              </thead>
              {props.loading && renderSkeleton()}
              {!props.loading && rows.value}
            </table>
          </div>
          {dataLength.value > pageLength.value && (
            <DsPagination
              total={dataLength.value}
              page={currentPage.value}
              onPageChange={(value) => (currentPage.value = value)}
              pageLength={pageLength.value}
              onPageLengthChange={(value) => {
                pageLength.value = value
              }}
            />
          )}
        </div>
      )
    },
  }))()
