export const formatDate = (value: string | undefined | null): string => {
    if (value === undefined || value === null) return '-'
    return new Intl.DateTimeFormat('fr-FR').format(Date.parse(value))
}

export const formatDatetime = (value: string | undefined | null): string => {
    if (value === undefined || value === null) return '-'
    return new Intl.DateTimeFormat('en-EN', { dateStyle: 'full', timeStyle: 'short' } as any).format(Date.parse(value))
}

export const formatTimeSpent = (value: string | number | undefined | null) => {
    if (value === undefined || value === null) return '-'

    const milliSecondInWeek = 604800000
    const timeElapsed = Date.now() - (typeof (value) === 'number' ? value : new Date(value).getTime())

    let unit = timeElapsed / milliSecondInWeek < 1 ? 'Day' : 'Week'
    const timeByUnit = Math.floor(timeElapsed / (unit === 'Day' ? milliSecondInWeek / 7 : milliSecondInWeek))
    if (timeByUnit > 1) unit = unit + 's'
    return `${timeByUnit < 1 ? '<1' : timeByUnit} ${unit}`
}