export function sortWorkflow(workflow: Record<string, any>, taskDependencie: string | null = null): Record<string, any> {
  let rootTask: Record<string, any> = {}

  for (const [taskId, task] of Object.entries(workflow)) {
    if (taskDependencie === null && task.previous_tasks.length == 0 || taskDependencie !== null && Object.values(task.previous_tasks).includes(taskDependencie)) {
      rootTask[taskId] = task
    }
  }
  for (const taskId of Object.keys(rootTask)) {
    const childTasks = sortWorkflow(workflow, taskId)
    for (const childTaskId of Object.keys(childTasks)) {
      if (childTaskId in rootTask) {
        delete rootTask[childTaskId]
      }
    }
    rootTask = { ...rootTask, ...childTasks }
  }
  return rootTask
}