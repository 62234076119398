import { defineComponent, ref, PropType } from 'vue'

import { ElDialog, ElSelect, ElOption, ElButton } from 'element-plus'

import DsForm, { Model } from '@/components/DsForm'
interface templateModel {
  model: Model,
  default: any
}

export default (() =>
  defineComponent({
    name: 'DsView',
    components: {
      ElDialog,
      DsForm
    },
    props: {
      templateForm: { type: Object as PropType<templateModel>, required: true },
      open: { type: Boolean, required: true },
      loading: { type: Boolean, required: false },
      value: { type: Object as PropType<Record<string, any>>, required: true },
      handleChange: {
        type: Function as PropType<(value: any) => void>,
        required: true,
      },
      itemKeyName: { type: String, required: true },
      relations: { type: Object as PropType<Record<string, any>>, required: true },
      onClose: { type: Function as PropType<() => void>, required: true },
      onSelect: { type: Function as PropType<(value: any) => void>, required: true }
    },
    setup(props) {
      return () => (
        <ElDialog
          title='Select a template'
          modelValue={props.open}
          lockScroll={true}
          onClose={props.onClose}
          v-slots={{
            default: () => (
              <div>
                <DsForm
                  model={props.templateForm.model}
                  document="template"
                  value={props.value}
                  handleChange={(value: any) => props.handleChange(value)}
                  relations={props.relations}
                />
                <ElButton style="width:100%;margin-top:2em" size='small' disabled={Object.values(props.value).some((value) => value === null)} onClick={() => props.onSelect(props.value)}>Next</ElButton>
              </div>
            )
          }}
        >
        </ElDialog >
      )
    },
  }))()
