import { defineComponent, ref, Ref, PropType } from 'vue'

import { ElButton } from 'element-plus'

import DsDialog from '@/components/DsDialog'
import DsForm, { Model } from '@/components/DsForm'
import { showMessage } from '@/utils/messages'

export default defineComponent({
  name: 'DsCreateDialog',
  components: { DsDialog, DsForm },
  props: {
    open: { type: Boolean, required: true },
    name: { type: String, required: true },
    title: { type: String, required: true },
    createButtonLabel: { type: String, required: false, default: 'Create' },
    cancelButtonLabel: { type: String, required: false, default: 'Cancel' },
    model: { type: Object as PropType<Model>, required: true },
    value: { type: Object as PropType<Record<string, any>>, required: true },
    relations: {
      type: Object as PropType<Record<string, any>>,
      required: true,
    },
    handleChange: {
      type: Function as PropType<(value: Record<string, any>) => void>,
      required: true,
    },
    handleCreate: {
      type: Function as PropType<() => Promise<void>>,
      required: true,
    },
    disabled: { type: Boolean, required: false, default: false },
    onClose: { type: Function as PropType<() => void>, required: true },
    onClosed: { type: Function as PropType<() => void>, required: false },
  },
  setup(props, { expose }) {
    const formRef: Ref<typeof DsForm | null> = ref(null)
    const handleCreate = async () => {
      const isValid = await formRef.value?.validate()
      if (!isValid) return
      try {
        await props.handleCreate()
      } catch (error) {
        //pass
      }
    }

    const validate = async (): Promise<boolean> => {
      return formRef.value?.validate()
    }

    const clearValidate = () => {
      formRef.value?.clearValidate()
    }

    expose({ validate, clearValidate })

    return () => (
      <>
        <DsDialog
          open={props.open}
          title={props.title}
          name={props.title}
          onClose={() => props.onClose()}
          onClosed={() => {
            if (props.onClosed !== undefined) {
              props.onClosed()
            }
            formRef.value?.clearValidate()
          }}
        >
          <DsForm
            ref={formRef}
            document={props.name}
            model={props.model}
            value={props.value}
            relations={props.relations}
            handleChange={props.handleChange}
          />
          <div
            v-slots="footer"
            style="display: flex; justify-content: flex-end;"
          >
            <ElButton size="small" onClick={props.onClose}>
              {props.cancelButtonLabel}
            </ElButton>
            <ElButton
              size="small"
              type="primary"
              onClick={() => handleCreate()}
            >
              {props.createButtonLabel}
            </ElButton>
          </div>
        </DsDialog>
      </>
    )
  },
})
