import { defineComponent, ref, PropType } from 'vue'

import { ElDialog, ElButton } from 'element-plus'

export default defineComponent({
  name: 'DsDialog',
  components: { ElDialog, ElButton },
  props: {
    open: { type: Boolean, required: true },
    name: { type: String, required: true },
    title: { type: String, required: true },
    onClose: { type: Function as PropType<() => void> },
    onClosed: { type: Function as PropType<() => void>, required: false },
  },
  setup(props, { slots }) {
    return () => (
      <ElDialog
        title={props.title}
        modelValue={props.open}
        onClose={props.onClose}
        onClosed={props.onClosed}
      >
        {slots.default && slots.default()}
        {slots.footer && (
          <div
            style="display: flex; justify-content: flex-end"
            v-slots="footer"
          >
            {slots.footer()}
          </div>
        )}
      </ElDialog>
    )
  },
})
