import { defineComponent, ref, Ref, PropType, watch, computed } from 'vue'

import {
  ElButton,
  ElTimeline,
  ElTimelineItem,
  ElSelect,
  ElOption,
  ElMessageBox,
  ElInput,
} from 'element-plus'
import { useStore } from '@/store'
import { sortWorkflow } from '@/utils/workflow'
import DsDrawer from '@/components/DsDrawer'
import DsForm, { Model } from '@/components/DsForm'
import { showMessage } from '@/utils/messages'
import { apiCall } from '@/utils/requests'
import { formatDate, formatDatetime } from '@/utils/datetimes'

import './DsUpdateDrawer.css'
import { CircleCheck } from '@element-plus/icons'

export default defineComponent({
  name: 'DsUpdateDrawer',
  components: { DsDrawer, DsForm, ElButton, CircleCheck, ElInput },
  props: {
    open: { type: Boolean, required: true },
    name: { type: String, required: true },
    title: { type: String, required: true },
    model: { type: Object as PropType<Model>, required: true },
    workflow: { type: Boolean, required: false },
    history: { type: Boolean, required: false, default: true },
    value: { type: Object as PropType<Record<string, any>>, required: true },
    relations: {
      type: Object as PropType<Record<string, any>>,
      required: true,
    },
    headerKey: { type: String, required: false },
    onAction: {
      type: Function as PropType<
        (
          taskName: string,
          actionName?: string,
          payload?: { [key: string]: any }
        ) => void
      >,
      required: true,
    },
    handleChange: {
      type: Function as PropType<(value: Record<string, any>) => void>,
      required: true,
    },
    handleUpdate: {
      type: Function as PropType<() => Promise<void>>,
      required: true,
    },
    handleDelete: {
      type: Function as PropType<() => Promise<void>>,
      required: true,
    },
    onClose: { type: Function as PropType<() => void>, required: true },
    onClosed: { type: Function as PropType<() => void>, required: false },
    onCancel: { type: Function as PropType<() => void>, required: false },
  },
  setup(props, { expose }) {
    const formRef: Ref<typeof DsForm | null> = ref(null)

    const disabled = ref(true)
    const history = ref([])

    const taskIssueText = ref('')

    const selectedTaskName = ref<string | null>(null)
    const selectedTask = computed<any | null>(
      () => props.value.workflow[selectedTaskName.value || '']
    )

    watch(
      () => props.value.id,
      () => {
        if (props.workflow && props.value.workflow) {
          selectedTaskName.value =
            Object.keys(props.value.workflow).find(
              (key) => props.value.workflow[key].status === 'active'
            ) || null
        }
      }
    )

    watch(
      () => props.value,
      async () => {
        if (!props.value.id || !props.history) return
        history.value = await apiCall(
          `${props.title.toLocaleLowerCase()}/${props.value.id}/history`
        )
      }
    )
    const store = useStore()
    const user = computed(() => store.state.user.user)

    const handleUpdate = async () => {
      const isValid = await formRef.value?.validate()
      if (!isValid) return
      try {
        await props.handleUpdate()
        disabled.value = true
      } catch (error) {
        return
      }
    }

    const handleCancel = () => {
      props.onCancel && props.onCancel()
      formRef.value?.clearValidate()
      disabled.value = !disabled.value
    }

    const handleDelete = async () => {
      try {
        await ElMessageBox.confirm(
          'This action will delete this ressource !',
          'Are you sure ?'
        )
        props.handleDelete()
        disabled.value = true
      } catch (error) {
        if (error === 'cancel') {
          return
        }
        showMessage('Error', 'error')
      }
    }

    const setDisabled = (value: boolean) => (disabled.value = value)

    const validate = async (): Promise<boolean> => {
      return formRef.value?.validate()
    }

    const clearValidate = () => {
      formRef.value?.clearValidate()
    }

    expose({ validate, clearValidate, setDisabled })

    return () => (
      <>
        <DsDrawer
          open={props.open}
          onClose={() => {
            selectedTaskName.value = null
            props.onClose()
          }}
          onClosed={() => {
            if (props.onClosed !== undefined) {
              props.onClosed()
            }
            formRef.value?.clearValidate()
          }}
        >
          <div class="header" v-slots="header">
            <div class="header-info">
              <div>{props.title}</div>
              <div>
                {props.value.created_at && (
                  <div style="text-align: right;">
                    Created on {formatDate(props.value.created_at)}
                  </div>
                )}
                {props.value.updated_at && (
                  <div style="text-align: right;">
                    Modified on {formatDate(props.value.updated_at)}
                  </div>
                )}
              </div>
            </div>
            <h1 tabindex="1" class="header-title">
              {props.headerKey
                ? props.value[props.headerKey]
                : props.value.name}
            </h1>
            {/* {props.subTitles.map((subTitle) => (
              <div class="header-subtitle" key="index">
                {subTitle}
              </div>
            ))}
            {props.labels.length > 0 && (
              <div style="margin-top: 1.5em; display: flex; gap: 0.5em;">
                {props.labels.map((label) => (
                  <DsTag key={label}>{{ label }}</DsTag>
                ))}
              </div>
            )} */}
            {props.value.comment !== undefined &&
              props.value.comment !== null &&
              props.value.comment !== '' && (
                <div class="header-comment">
                  <span>{props.value.comment}</span>
                </div>
              )}
          </div>
          <div style="border-bottom: 0.5px solid #dcdfe6; margin-left: -2em; margin-right: -2em;" />
          {props.workflow && props.value.workflow && (
            <>
              <div style="display: flex; justify-content: space-between; gap: 1em; margin: 1em 0;">
                <ElButton
                  size="small"
                  onClick={() => (selectedTaskName.value = null)}
                >
                  {props.title}
                </ElButton>
                <ElSelect
                  style="flex: 1"
                  size="small"
                  placeholder="Select action..."
                  onChange={(value) => (selectedTaskName.value = value)}
                  valueKey="label"
                  name="label"
                  modelValue={selectedTaskName.value || []}
                >
                  {Object.entries(sortWorkflow(props.value.workflow)).map(
                    ([taskId, task]: any) => {
                      return (
                        <ElOption
                          key={taskId}
                          value={taskId}
                          label={task.label}
                          disabled={
                            task.status === null ||
                            (user.value?.uid !== props.value.owner &&
                              user.value?.uid !== task.owner)
                          }
                        >
                          {() => {
                            if (task.status === 'active') {
                              return (
                                <span>
                                  {task.label}
                                  <span style="float: right; font-size: 10px;">
                                    ⏳
                                  </span>
                                </span>
                              )
                            } else if (task.status !== null) {
                              return (
                                <span>
                                  {task.label}
                                  <span style="float: right; font-size: 10px">
                                    <span style="vertical-align: middle; margin-right: 0.5em;">
                                      <CircleCheck style="height: 1em"></CircleCheck>
                                    </span>
                                    <span>
                                      {new Date(task.status).toDateString()}
                                    </span>
                                  </span>
                                </span>
                              )
                            } else {
                              return <span>{task.label}</span>
                            }
                          }}
                        </ElOption>
                      )
                    }
                  )}
                </ElSelect>
              </div>
              <div style="border-bottom: 0.5px solid #dcdfe6; margin-left: -2em; margin-right: -2em;" />
            </>
          )}
          {props.value.workflow && selectedTaskName.value ? (
            <div style="display: flex; justify-content: space-around;flex-direction:column;padding-bottom: 20px;">
              <h1>Task</h1>
              {selectedTask.value.description && (
                <span style="background-color: #eef1f5;border-radius: 2px;padding: 10px;">
                  {selectedTask.value.description}
                </span>
              )}
              <ElButton
                size="mini"
                onClick={() =>
                  selectedTaskName.value
                    ? props.onAction(selectedTaskName.value)
                    : console.log('No selected Task')
                }
                plain
                disabled={selectedTask.value.status !== 'active'}
              >
                {selectedTask.value.status === 'active'
                  ? `Complete the task : ${selectedTask.value.label}`
                  : 'You have completed the task'}
              </ElButton>
              <h1>Issue</h1>
              {selectedTask.value.error ? (
                <>
                  <span style="background-color: #fcedeb;border-radius: 2px;padding: 10px;">
                    {selectedTask.value.error}
                  </span>
                  <ElButton
                    size="mini"
                    onClick={() =>
                      selectedTaskName.value
                        ? props.onAction(
                            selectedTaskName.value,
                            'declare_error',
                            { error_message: null }
                          )
                        : console.log('No selected Task')
                    }
                    style="margin-top:5px"
                    plain
                    disabled={selectedTask.value.status !== 'active'}
                  >
                    Clear the issue
                  </ElButton>
                </>
              ) : (
                <>
                  <ElInput
                    style="margin-top:5px"
                    disabled={selectedTask.value.status !== 'active'}
                    type="textarea"
                    placeholder="Describe the issue"
                    modelValue={taskIssueText.value}
                    onInput={(value: string) => (taskIssueText.value = value)}
                  />

                  <ElButton
                    size="mini"
                    onClick={() => {
                      if (selectedTaskName.value) {
                        props.onAction(
                          selectedTaskName.value,
                          'declare_error',
                          { error_message: taskIssueText.value }
                        )
                        taskIssueText.value = ''
                      } else {
                        console.log('No selected Task')
                      }
                    }}
                    style="margin-top:5px"
                    plain
                    disabled={selectedTask.value.status !== 'active'}
                    type={'danger'}
                  >
                    Declare an issue
                  </ElButton>
                </>
              )}
            </div>
          ) : (
            <div>
              <div style="display: flex; align-items: center; justify-content: space-between;">
                <h1>Settings</h1>
                <div>
                  {!disabled.value && (
                    <ElButton
                      size="mini"
                      onClick={handleDelete}
                      disabled={disabled.value}
                      type="danger"
                      plain
                    >
                      Delete
                    </ElButton>
                  )}

                  <ElButton
                    size="mini"
                    onClick={handleUpdate}
                    disabled={disabled.value}
                  >
                    Save changes
                  </ElButton>
                  {disabled.value && (
                    <ElButton
                      size="mini"
                      onClick={() => (disabled.value = !disabled.value)}
                    >
                      Edit
                    </ElButton>
                  )}
                  {!disabled.value && (
                    <ElButton size="mini" onClick={() => handleCancel()}>
                      Cancel
                    </ElButton>
                  )}
                </div>
              </div>
              <DsForm
                ref={formRef}
                document={props.name}
                model={props.model}
                value={props.value}
                handleChange={props.handleChange}
                disabled={disabled.value}
                relations={props.relations}
                createForm={false}
              />
              {props.history && (
                <>
                  <div style="border-bottom: 0.5px solid #dcdfe6; margin-left: -2em; margin-right: -2em;" />
                  <div>
                    <h1>History</h1>
                    <ElTimeline style="padding-left: 0;">
                      {history.value.map((v: any, index) => (
                        <ElTimelineItem
                          key={index}
                          timestamp={formatDatetime(v.updated_at)}
                        >
                          {index === history.value.length - 1
                            ? `Created by John Doe`
                            : `Updated by John Doe`}
                        </ElTimelineItem>
                      ))}
                    </ElTimeline>
                  </div>
                </>
              )}
            </div>
          )}
        </DsDrawer>
      </>
    )
  },
})
