import { defineComponent, PropType, computed } from 'vue'

import {
  ElRadioGroup,
  ElRadioButton,
  ElButtonGroup,
  ElButton,
} from 'element-plus'
import { ArrowLeft, ArrowRight } from '@element-plus/icons'

import './DsPagination.css'

export default defineComponent({
  components: { ElRadioGroup, ElRadioButton, ElButtonGroup, ElButton },
  props: {
    pageLength: { type: Number, required: true },
    onPageLengthChange: {
      type: Function as PropType<(value: number) => void>,
      required: true,
    },
    page: { type: Number, required: true },
    onPageChange: {
      type: Function as PropType<(value: number) => void>,
      required: true,
    },
    total: { type: Number, required: true },
    pageLengthOptions: {
      type: Array as PropType<number[]>,
      required: false,
      default: () => [25, 50, 100],
    },
  },
  setup(props) {
    const firstElement = computed(() => props.page * props.pageLength + 1)
    const lastElement = computed(() =>
      (props.page + 1) * props.pageLength > props.total
        ? props.total
        : (props.page + 1) * props.pageLength
    )

    return () => (
      <div class="pagination">
        <div class="left-group">
          <ElRadioGroup
            modelValue={props.pageLength}
            onChange={(value: any) => {
              props.onPageLengthChange(value)
              props.onPageChange(0)
            }}
            size="mini"
            text-color="#606266"
            fill="rgb(238, 241, 245)"
            style="margin-right: 12px"
          >
            {props.pageLengthOptions.map((value) => (
              <ElRadioButton
                key={value}
                label={value}
                disabled={props.pageLength == value}
              ></ElRadioButton>
            ))}
          </ElRadioGroup>
        </div>
        <div class="right-group">
          <p>
            {firstElement.value} - {lastElement.value} on {props.total}
          </p>
          <ElButtonGroup>
            <ElButton
              disabled={props.page <= 0}
              icon={ArrowLeft}
              size="mini"
              onClick={() => props.onPageChange(props.page - 1)}
            ></ElButton>
            <ElButton
              disabled={
                props.page + 1 >= Math.ceil(props.total / props.pageLength)
              }
              icon={ArrowRight}
              size="mini"
              onClick={() => props.onPageChange(props.page + 1)}
            ></ElButton>
          </ElButtonGroup>
        </div>
      </div>
    )
  },
})
