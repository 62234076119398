import { cloneDeep } from 'lodash'

import { defineComponent, PropType, ref, computed, watch } from 'vue'
import { showMessage } from "@/utils/messages"
import { ElButton, ElInput, ElSelect, ElDatePicker, ElOption, ElPopover, ElDropdown, ElDropdownMenu, ElDropdownItem, ElTag } from 'element-plus'
import { Delete as DeleteIcon, Close as CloseIcon, Check as CheckIcon, Plus as PlusIcon } from '@element-plus/icons'

import { Preference } from '@/store/preferences/preferences'
import { useStore } from '@/store'

import '@/components/FilterBuilder.css'


type OperatorType = 'enum' | 'date' | 'daterange' | 'number' | 'string' | 'multiSelect'

interface OperatorOption { value: string; label: string }

const optionsOperator: Record<OperatorType, OperatorOption[]> = {
  string: [
    {
      value: 'CONTAINS',
      label: 'Contains'
    },
    {
      value: 'DOES_NOT_CONTAIN',
      label: 'Does not contain'
    },
    { label: 'Is empty', value: 'IS_EMPTY' },
    { label: 'Is not empty', value: 'IS_NOT_EMPTY' }
  ],
  enum: [
    {
      value: 'CONTAINS',
      label: 'Contains'
    },
    {
      value: 'DOES_NOT_CONTAIN',
      label: 'Does not contain'
    },
    { label: 'Is empty', value: 'IS_EMPTY' }
  ],
  daterange: [
    { label: 'Includes (⊃)', value: 'INCLUDES' },
    { label: 'Intersects (∩)', value: 'INTERSECTS' }
  ],
  date: [
    { label: 'Is', value: 'IS' },
    { label: 'Is between', value: 'IS_BETWEEN' },
    { label: 'Is before', value: 'IS_BEFORE' },
    { label: 'Is after', value: 'IS_AFTER' }
  ],
  number: [
    { label: '=', value: 'IS_EQUAL' },
    { label: '≠', value: 'IS_DIFFERENT' },
    { label: '>', value: 'IS_GREATER' },
    { label: '<', value: 'IS_LESS' },
    { label: '≥', value: 'IS_GREATER_OR_EQUAL' },
    { label: '≤', value: 'IS_LESS_OR_EQUAL' },
    { label: 'Is empty', value: 'IS_EMPTY' },
    { label: 'Is not empty', value: 'IS_NOT_EMPTY' }
  ], multiSelect: [
    {
      value: 'CONTAINS',
      label: 'Contains'
    },
    {
      value: 'DOES_NOT_CONTAIN',
      label: 'Does not contain'
    },
    { label: 'Is empty', value: 'IS_EMPTY' }
  ],
}

export interface FilterOption {
  label: string,
  type:
  'string' |
  'enum' |
  'daterange' |
  'date' |
  'number' |
  'multiSelect',
  values: { label: string | number | boolean, value: string | number | boolean }[]
}


interface Filter {
  type: 'filter'
  property: string
  operator: string
  value: any
}


export interface FilterGroup {
  type: 'group'
  filters: (Filter | FilterGroup)[]
  condition: 'OR' | 'AND'
}


type Options = Record<string, { type: OperatorType; label: string, values: { value: string; label: string }[] }>


const getPropertyOperators = (options: Options, property: string) => {
  return (options[property] || {}).type in optionsOperator ? optionsOperator[options[property].type] : []
}

const getOperator = (options: Options, prevProperty: string, nextProperty: string, prevOperator?: string) => {
  if (prevOperator !== undefined && (options[prevProperty] || {}).type === (options[nextProperty] || {}).type) {
    return prevOperator
  }
  return (getPropertyOperators(options, nextProperty)[0] || {}).value
}


const FilterModule = defineComponent({
  name: 'FilterModule',
  props: {
    filter: { type: Object as PropType<Filter>, required: true },
    options: { type: Object as PropType<Options>, required: true },
    handleChange: { type: Function as PropType<(value: Filter) => void>, required: true },
  },
  components: { ElSelect, ElOption, ElDatePicker, ElInput },
  setup(props) {

    const handleChange = (key: string, value: unknown) => {
      props.handleChange({ ...props.filter, [key]: value })
    }

    const handlePropertyChange = (property: string) => {
      props.handleChange({
        ...props.filter,
        operator: getOperator(props.options, props.filter.property, property, props.filter.operator),
        property,
        value: undefined
      })
    }

    const renderSelector = (option: { type: string; values: { value: string; label: string }[] }, value: any) => {
      switch (option.type) {
        case 'multiSelect':
          return (
            <ElSelect
              style="flex: 1;"
              modelValue={value}
              popperAppendToBody={false}
              onChange={value => handleChange('value', value)}
              size="small"
              placeholder="Select"
              multiple
              collapse-tags
              filterable
            >
              {(option.values || []).map((item) => (
                <ElOption
                  key={item.value}
                  label={item.label}
                  value={item.value}
                />
              ))}
            </ElSelect>
          )
        case 'enum':
          return (
            <ElSelect
              style="flex: 1;"
              modelValue={value}
              onChange={value => handleChange('value', value)}
              size="small"
              placeholder="Select"
              multiple
              collapse-tags
              filterable
              popperAppendToBody={false}
            >
              {(option.values || []).map(item => (
                <ElOption
                  key={item.value}
                  label={item.label}
                  value={item.value}
                />
              ))}
            </ElSelect>
          )
        case 'date':
          return (
            <ElDatePicker
              style="flex: 1;"
              modelValue={value}
              {...{
                'onUpdate:modelValue': (value) => handleChange('value', value)
              }}
              type="date"
              value-format="yyyy-MM-dd"
              size="small"
              clearable
            />
          )
        case 'daterange':
          return (
            <ElDatePicker
              style="flex: 1;"
              modelValue={value}
              {...{
                'onUpdate:modelValue': (value) => handleChange('value', value)
              }}
              type="daterange"
              value-format="yyyy-MM-dd"
              start-placeholder="Start"
              end-placeholder="End"
              unlink-panels
              size="small"
              clearable
            />
          )
        case 'number':
          return (
            <div class="el-input el-input--small el-input--suffix is-focus">
              <input class="el-input__inner"
                type='number'
                autocomplete="off"
                value={value}
                onInput={(event) => {
                  const target = event.target as HTMLInputElement;
                  handleChange('value', parseFloat(target.value))
                }}></input></div>
          )
        case 'string':
          return (
            <ElInput
              clearable
              size="small"
              style="flex: 1;"
              modelValue={value}
              onInput={value => handleChange('value', value)}
            />
          )
        default:
          break
      }
    }

    return () => (
      <div style="display: flex; gap: 0.5em; flex: 1">
        <ElSelect
          style="flex: 0 0 30%;"
          modelValue={props.filter.property}
          onChange={value => handlePropertyChange(value)}
          size="small"
          placeholder="Select"
          filterable
          popperAppendToBody={false}
        >
          {Object.entries(props.options).map(([property, { label }]) => (
            <ElOption key={property} label={label} value={property} />
          ))}
        </ElSelect>

        <ElSelect
          style="flex: 0 0 30%;"
          modelValue={props.filter.operator}
          onChange={value => handleChange('operator', value)}
          size="small"
          placeholder="Select"
          filterable
          popperAppendToBody={false}
        >
          {getPropertyOperators(props.options, props.filter.property).map((item) => (
            <ElOption key={item.value} label={item.label} value={item.value} />
          ))}
        </ElSelect>
        {renderSelector(
          props.options[props.filter.property] || {},
          props.filter.value,
        )}
      </div>
    )
  }
})


const optionsCondition = [
  {
    value: 'OR',
    label: 'Or'
  },
  {
    value: 'AND',
    label: 'And'
  }
]

const GroupeModule = defineComponent({
  name: 'GroupeModule',
  props: {
    group: { type: Object as PropType<FilterGroup>, required: true },
    depth: { type: Number, required: false, default: 0 },
    options: { type: Object as PropType<Options>, required: true, },
    handleChange: { type: Function as PropType<(value: FilterGroup) => void>, required: true },
    saveFilter: { type: Function as PropType<(name: string) => Promise<void>> },
    showApply: { type: Boolean },
    showSaveFilter: { type: Boolean },
    loading: { type: Boolean, required: false, default: false },
    onApply: { type: Function, required: true },
    onReset: { type: Function, required: true }
  },
  setup(props) {
    const handleChange = (index: number, filter: Filter | FilterGroup) => {
      const group: FilterGroup = cloneDeep(props.group)
      group.filters[index] = filter
      props.handleChange(group)
    }

    const handleConditionChange = (condition: 'OR' | 'AND') => {
      props.handleChange({ ...props.group, condition })
    }

    const handleCommand = (command: string, index: number) => {
      const group: FilterGroup = cloneDeep(props.group)
      switch (command) {
        case 'remove':
          group.filters.splice(index, 1)
          if (props.depth === 0 && group.filters.length === 0) {
            props.onApply()
          }
          break
        case 'add-condition':
          group.filters.push({
            type: 'filter',
            property: Object.keys(props.options)[0],
            operator: getOperator(props.options, '', Object.keys(props.options)[0]),
            value: ''
          })
          break
        case 'add-condition-group':
          group.filters.push({
            type: 'group',
            filters: [
              { type: 'filter', property: Object.keys(props.options)[0], operator: getOperator(props.options, '', Object.keys(props.options)[0]), value: '' }
            ],
            condition: 'AND'
          })
          break
        default:
          break
      }
      props.handleChange(group)
    }

    const style = computed(() => {
      if (props.depth === 0) return
      return {
        border: '1px solid rgba(144, 147, 153, 0.1)',
        backgroundColor: 'rgba(144, 147, 153, 0.1)'
      }
    })


    const name = ref<string>('')
    const showInput = ref<boolean>(false)
    return () => (
      <div class="group-container" style={style.value}>
        {props.depth === 0 && props.group.filters.length !== 0 && <span style='display:flex;justify-content:end'><ElButton type='text' size="small" onClick={() => props.onReset()}>Reset Filters</ElButton></span>}
        {props.depth === 0 && props.group.filters.length === 0 && 'No filters are applied'}
        {(props.group.filters || []).map((filter, index) => {
          return (
            <div style="display: flex;">
              <div
                class="select-condition"
                style="width: 100px; padding-right: 0.5em; gap: 0.5em; flex: 0 0 75px;"
              >
                {index === 0 ? (
                  <div style="padding-top: 7px;">Where</div>
                ) : (
                  <ElSelect
                    class="select-condition"
                    disabled={index > 1}
                    modelValue={props.group.condition}
                    onChange={(value) => handleConditionChange(value)}
                    size="small"
                    placeholder="Select"
                    style="width: 100%"
                    popperAppendToBody={false}
                  >
                    {optionsCondition.map(item => (
                      <ElOption
                        key={item.value}
                        label={item.label}
                        value={item.value}
                      />
                    ))}
                  </ElSelect>
                )}
              </div>
              {filter.type === 'group' ? (
                <GroupeModule
                  key={index}
                  group={filter}
                  depth={props.depth + 1}
                  handleChange={(value) => handleChange(index, value)}
                  options={props.options}
                  onApply={props.onApply}
                  onReset={props.onReset}
                />
              ) : (
                <FilterModule
                  key={index}
                  filter={filter}
                  handleChange={(value) => handleChange(index, value)}
                  options={props.options}
                />
              )}
              <div style="margin-left: 0.5em">
                <ElButton
                  type="text"
                  size="small"
                  onClick={() => handleCommand('remove', index)}
                  icon={DeleteIcon}
                >
                </ElButton>
              </div>
            </div>
          )
        })}
        <div style="margin-top: 0.5em; display: flex; justify-content: space-between;">
          <div style="display: flex; gap: 0.5em;">
            <ElButton
              size="small"
              type="text"
              icon={PlusIcon}
              onClick={() => {
                handleCommand('add-condition', props.group.filters.length)
              }}
            >
              Add condition
            </ElButton>
            <ElButton
              size="small"
              type="text"
              icon={PlusIcon}
              onClick={() => {
                handleCommand(
                  'add-condition-group',
                  props.group.filters.length
                )
              }}
            >
              Add condition group
            </ElButton>
          </div>
          {props.depth === 0 && props.group.filters.length !== 0 && (
            <div style="display: flex; gap: 1em;">
              {props.showSaveFilter && !showInput.value && (
                <ElButton
                  size="small"
                  type="text"
                  onClick={() => {
                    showInput.value = true
                  }}
                >
                  Save filter
                </ElButton>
              )}
              {(showInput.value && props.saveFilter) && (
                <div style="display: flex; align-items: center;">
                  <ElInput
                    size="mini"
                    placeholder="Filter name..."
                    modelValue={name.value}
                    onInput={value => (name.value = value)}
                  />
                  <ElButton
                    style="margin-left: 1em;"
                    size="small"
                    type="text"
                    onClick={() => {
                      showInput.value = false
                      name.value = ''
                    }}
                    icon={CloseIcon}
                  />
                  <ElButton
                    style="margin-right: 1em;"
                    size="small"
                    type="text"
                    onClick={async () => {
                      try {
                        if (props.saveFilter) await props.saveFilter(name.value)
                        showInput.value = false
                        name.value = ''
                        showMessage('Filter is saved', 'success')
                      } catch (error) {
                        if (typeof (error) === 'string') showMessage(error, "error")
                      }
                    }}
                    icon={CheckIcon}
                  />
                </div>
              )}
              {props.showApply && (
                <ElButton
                  type="primary"
                  size="small"
                  onClick={() => props.onApply()}
                  loading={props.loading}
                >
                  Apply
                </ElButton>
              )}
            </div>
          )}
        </div>
      </div>
    )
  }
}
)


export default defineComponent({
  name: 'FilterBuilder',
  props: {
    value: { type: Object as PropType<FilterGroup>, required: true },
    options: { type: Object, required: true },
    collection: { type: String, required: true },
    showApply: { type: Boolean, required: false, default: true },
    showPopover: { type: Boolean, required: false, default: true },
    loading: { type: Boolean, required: false, default: false },
    onApply: { type: Function as PropType<() => Promise<void>>, required: true },
    onInput: { type: Function as PropType<(value: FilterGroup) => void>, required: true },
  },
  components: {
    ElButton, ElPopover, ElDropdown, ElDropdownMenu, ElDropdownItem, ElTag
  },
  setup(props) {
    const store = useStore()
    const filterViews = computed<Array<Preference> | null>(() => store.getters.filtersPreferences)

    const resetFilters = () => {
      props.onInput({ type: 'group', filters: [], condition: 'AND' })
      props.onApply()
    }

    const handleCreateView = async (name: string, filter: FilterGroup) => {
      store.dispatch('createPreference', { name: name, collection: props.collection, type: 'filter', value: filter })
    }

    const handleDeleteView = async (event: Event, id: string) => {
      event.stopPropagation()
      store.dispatch('deletePreference', id)
    }

    const handleSelectView = async (id: string) => {
      const view = await store.dispatch('getPreferenceById', id)
      props.onInput(view.value)
      props.onApply()
    }

    const handleChange = (value: FilterGroup) => {
      props.onInput(value)
    }


    return () => (
      <>
        <ElDropdown
          size="small"
          class={
            props.value.type === 'group' &&
            props.value.filters.length !== 0 &&
            props.value.filters[0].type === 'filter' &&
            props.value.filters[0].property !== '' && 'active'
          }
          trigger="click"
          onCommand={(id: string) => handleSelectView(id)}
          splitButton
          v-slots={{
            default: () => (
              <ElPopover
                trigger="click"
                v-slots={{
                  reference: () => <div class='filter-split-button' style="margin: -9px -15px; padding: 9px 15px;"
                  >
                    Filters {
                      props.value.type === 'group' &&
                      props.value.filters.length !== 0 &&
                      props.value.filters[0].type === 'filter' && props.value.filters[0].property !== '' && <span
                      >
                        ({props.value.filters.length})
                      </span>}
                  </div>,
                }}
                width="700px"
              ><div>
                  <GroupeModule
                    options={props.options}
                    group={props.value}
                    handleChange={handleChange}
                    saveFilter={(viewName: string) => handleCreateView(viewName, props.value)}
                    showSaveFilter={true}
                    showApply={props.showApply}
                    onApply={props.onApply}
                    onReset={resetFilters}
                    loading={props.loading}
                  ></GroupeModule></div>
              </ElPopover>),
            dropdown: () => (
              <ElDropdownMenu >
                {filterViews.value && filterViews.value.length === 0 ?
                  <ElDropdownItem disabled>
                    No saved filters
                  </ElDropdownItem> :
                  filterViews.value && filterViews.value.map(
                    (view: Preference, index: number) => {
                      return view.id && (
                        <ElDropdownItem
                          key={index}
                          command={view.id}
                        >
                          <div class="view-item">
                            <span class="view-item-name">
                              <ElTag size="mini">{view.name}</ElTag>
                            </span>
                            <div class="view-item-delete-container">
                              <ElButton
                                class="view-item-delete-button"
                                type="text"
                                size="small"
                                onClick={event => handleDeleteView(event, view.id || '')}
                                icon={DeleteIcon}
                              />
                            </div>
                          </div>
                        </ElDropdownItem >
                      )
                    })
                }
              </ElDropdownMenu >
            )
          }}
        />
      </>)
  },
})