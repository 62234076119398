import { cloneDeep } from 'lodash'
import { defineComponent, onMounted, ref, PropType, computed, watch, reactive } from 'vue'

import { FilterGroup, FilterOption } from '@/components/FilterBuilder'
import DsCreateDialog from '@/components/DsCreateDialog'
import DsUpdateDrawer from '@/components/DsUpdateDrawer'
import DsTable, { Action, Column } from '@/components/DsTable'
import DsTableGroupBy from '@/components/DsTableGroupBy'
import PdfViewer from '@/components/PdfViewer'
import DsTemplateSelect from '@/components/DsTemplateSelect'
import ActionButton, { actionButton } from './ActionButton'
import { Model } from '@/components/DsForm'
import { apiCall } from '@/utils/requests'
import { copyAllFiles, download } from '@/components/DsUpload'
import { ElInput, ElSelect, ElButton, } from 'element-plus'
import {
  DocumentCopy as DocumentCopyIcon,
  Edit as EditIcon,
  Search,
} from '@element-plus/icons'

import FilterBuilder from '@/components/FilterBuilder'
import DsExport, { exportParams } from '@/components/DsExport'

import { showMessage } from '@/utils/messages'
import { useStore } from '@/store'

interface View {
  collection: string
  title: string
  createButtonLabel?: string
  model: Model
  columns: Column[]
  searchBar?: boolean
  headerKey?: string
  workflow?: boolean
  templateModel?: { model: Model, default: any }
  groupByKey?: string
  rowKey: string
  mainAction?: Action
  secondaryActions?: (Action | string)[]
  history?: boolean
  actionButtons?: actionButton[]
  customRelations?: string[]
}


type RecordValue = Record<string, unknown> & { id?: string }


const isAction = (action: Action | string): action is Action => typeof action !== 'string'

export default defineComponent({
  name: 'DsView',
  components: {
    DsCreateDialog,
    DsUpdateDrawer,
    DsTable,
    ElInput,
    ElSelect,
    ElButton,
    Search,
    PdfViewer,
    DsTemplateSelect,
  },
  props: {
    view: { type: Object as PropType<View>, required: true },
    defaultValue: { type: Function as PropType<() => RecordValue>, required: true },
    backendDefaultFilter: { type: Object as PropType<FilterGroup>, required: false },
    frontendDefaultFilter: { type: Function as PropType<(value: any, relations: any) => boolean>, required: false },
    getRecords: {
      type: Function as PropType<() => Promise<RecordValue[]>>,
      required: false,
    },
  },
  setup(props, { expose }) {
    const store = useStore()

    const searchTerms = ref('')
    const loading = ref(false)
    const tableIsEmpty = ref(true)

    const editedValue = ref<RecordValue>(props.defaultValue())

    const drawerRef = ref<typeof DsUpdateDrawer | null>(null)
    const drawerOpen = ref(false)
    const dialogOpen = ref(false)

    const records = ref<RecordValue[]>([])

    const relations = reactive<{ [key: string]: { [key: string]: any } }>({})

    const templateDialog = ref({ open: false, loading: false })
    const templateDialogValue = ref<{ [key: string]: unknown }>({})

    watch(
      () => editedValue.value,
      () => relations['currentValue'] = editedValue.value,
    )

    watch(
      () => templateDialogValue.value,
      () => relations['templateValue'] = templateDialogValue.value,
    )

    watch(
      () => records.value,
      () => {
        tableIsEmpty.value = tableIsEmpty.value === true && records.value.length === 0

        if (filters.value.filters.length === 0) filterOptions.value = computeFilterOptions()

        relations[props.view.collection] = Object.fromEntries(
          records.value.map((v: any) => [v.id, v]))
      }
    )

    watch(
      () => ({ ...relations }),
      () => { if (filters.value.filters.length === 0) filterOptions.value = computeFilterOptions() }
    )
    function initRelations() {
      function getRelations(key: string, input: Model) {
        if ((input.type === 'string' || input.type === 'uuid') && input.model !== undefined) {
          relations[input.model] = {}
        }
        else if (input.type === 'array') {
          getRelations(key, input.items)
        } else if (input.type === 'object') {
          for (const [key, subInput] of Object.entries(input.properties)) {
            getRelations(key, subInput)
          }
        }
      }
      getRelations('object', props.view.model)

      for (const modelName of props.view.customRelations || []) {
        if (!Object.keys(relations).includes(modelName)) {
          relations[modelName] = {}
        }
      }

      if (props.view.templateModel) getRelations('object', props.view.templateModel.model); relations['templateValue'] = {}
      relations['currentValue'] = {}
    }

    function populateRelations() {
      async function getData(model: string) {
        let data: RecordValue[]
        if (model === 'users') {
          data = await apiCall(model)
        } else {
          data = await apiCall(model + "/get", "POST")
        }
        relations[model] = Object.fromEntries(
          data.map((v: any) => [v.id, v])
        )
      }

      for (const model of Object.keys(relations)) {
        if (model === 'currentValue') {
          relations[model] = editedValue.value
        } else if (model === 'templateValue') {
          relations[model] = templateDialogValue.value
        } else {
          getData(model)
        }
      }
    }

    onMounted(async () => {
      await refreshData()
      await store.dispatch('getPreferences', props.view.collection)

      initRelations()
      populateRelations()
    })

    const refreshData = async (filters: FilterGroup | null = null) => {
      loading.value = true
      if (!props.getRecords) {
        const baseFilter: FilterGroup | undefined = cloneDeep(props.backendDefaultFilter)
        if (baseFilter && filters) {
          baseFilter.filters.push(filters)
        }
        try {
          if (props.view.collection === 'users') {
            records.value = await apiCall('users')
          } else {
            records.value = await apiCall(props.view.collection + "/get", "POST", baseFilter || filters)
          }
        } catch {
          loading.value = false
        }

      } else {
        records.value = await props.getRecords()
      }
      records.value = props.frontendDefaultFilter ? records.value.filter(props.frontendDefaultFilter) : records.value
      loading.value = false
    }

    const handleChange = (value: RecordValue) => {
      editedValue.value = value
    }


    const handleCreate = async () => {
      const data = await apiCall(
        props.view.collection,
        'POST',
        editedValue.value
      )
      dialogOpen.value = false
      records.value.push(data)
    }

    const handleUpdate = async () => {
      const data = await apiCall(
        `${props.view.collection}/${editedValue.value.id || editedValue.value.uid
        }`,
        'PUT',
        editedValue.value
      )
      records.value.splice(recordIndex.value, 1, data)
    }

    const handleDelete = async () => {
      await apiCall(
        `${props.view.collection}/${editedValue.value.id || editedValue.value.uid
        }`,
        'DELETE'
      )
      records.value.splice(recordIndex.value, 1)
      drawerOpen.value = false
    }

    const resetRecord = () => {
      editedValue.value = { ...records.value[recordIndex.value] }
    }

    const duplicateRow = async (row: RecordValue) => {
      const newId = props.defaultValue().id || undefined
      editedValue.value = { ...row, id: newId }
      if (newId) {
        //TODO: Supprimer les files si on cancel le create
        // ex: const cancelCreate = async (row: any) => { }
        templateDialog.value.loading = true
        await copyAllFiles(props.view.model, editedValue.value, row.id, newId, null, props.view.collection)
        templateDialog.value.open = false
        templateDialog.value.loading = false
      }
      dialogOpen.value = true
    }

    const recordIndex = computed(() => {
      return records.value.findIndex(
        (r) => r[props.view.rowKey] === editedValue.value[props.view.rowKey]
      )
    })

    const secondaryActions = computed(() => {
      const actions: Action[] = []
      if (!props.view.secondaryActions || props.view.secondaryActions.includes('edit')) {
        actions.push({
          icon: EditIcon,
          label: 'Edit',
          callback: (row) => {
            editedValue.value = { ...row }
            drawerOpen.value = true
          },
        })
      }
      if (!props.view.secondaryActions || props.view.secondaryActions.includes('duplicate')) {
        actions.push(
          {
            icon: DocumentCopyIcon,
            label: 'Duplicate',
            callback: duplicateRow,
          })
      }

      actions.concat(props.view.secondaryActions?.filter(isAction) || [])
      return actions
    })

    const executeAction = async (
      taskName: string,
      actionName?: string,
      payload?: RecordValue
    ) => {
      const params: { task_name: string; action_name?: string } = {
        task_name: taskName
      }
      if (actionName) {
        params['action_name'] = actionName
      }
      const data = await apiCall(
        `${props.view.collection}/${editedValue.value.id || editedValue.value.uid
        }`,
        'PATCH',
        payload,
        params
      )
      records.value.splice(recordIndex.value, 1, data)
      editedValue.value = data
    }

    const filters = ref<FilterGroup>({
      type: 'group',
      condition: 'AND',
      filters: []
    })

    const filterOptions = ref<{ [key: string]: FilterOption }>({})

    const computeFilterOptions = (): { [key: string]: FilterOption } => {
      return (props.view.model.type === 'object' && Object.entries(props.view.model.properties) || []).reduce(
        (options: any, [field_key, field]: [string, Model]) => {
          if ('model' in field) {
            options[field_key] = { label: field.label, type: 'enum', values: field.model && relations && relations[field.model] && Object.values(relations[field.model]).map((value: any) => ({ value: value.id, label: value.name || value.id })) }
            return options
          }
          else if (field.type === 'string' && field.enum) {
            options[field_key] = { label: field.label, type: 'enum', values: field.enum }
            return options
          }
          else if (field.type === 'number') {
            options[field_key] = { label: field.label, type: field.type }
            return options
          }
          else {
            const field_options = new Set(records.value.map(value => value[field_key]))

            options[field_key] = { label: field.label, type: 'multiSelect', values: [...field_options].map((value: unknown) => { return { label: value, value: value } }), }
            return options
          }
        }, {})
    }


    const handleExport = async (exportParams: exportParams, filters: FilterGroup) => {
      const baseFilter: FilterGroup | undefined = cloneDeep(props.backendDefaultFilter)
      if (baseFilter && filters) {
        baseFilter.filters.push(filters)
      }

      let columns
      if (props.view.model.type === 'object') {
        columns = Object.entries(
          props.view.model.properties
        ).reduce((previous: { [key: string]: string }, [modelKey, model]) => {
          if (exportParams.type === 'ALL' || exportParams.columns.includes(modelKey)) {
            previous[modelKey] = model.label || modelKey
          }
          return previous
        }, {})
      }

      const payload = {
        format: exportParams.format,
        columns: columns,
        filters: exportParams.apply_filter ? baseFilter || filters : undefined
      }

      try {
        const response = await apiCall(`${props.view.collection}/export`, "POST", payload, undefined, { responseType: 'blob' })
        const filename = `export-${props.view.collection}.zip`

        download(response, filename)
      } catch (error) {
        console.log(error)
        showMessage(typeof (error) === 'string' ? error : "Error", "error")
      }
      loading.value = false
    }

    return () => (
      <>
        <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 2em;">
          <h1>{props.view.title}</h1>
          {props.view.createButtonLabel && <div>
            {props.view.actionButtons && <span style='margin-right: 0.5em;'>{props.view.actionButtons.map((actionButton: actionButton) => <ActionButton properties={actionButton} relations={relations} />)}</span>}
            <ElButton size="medium" onClick={() => (props.view.templateModel ? templateDialog.value.open = true : dialogOpen.value = true)}>
              {props.view.createButtonLabel}
            </ElButton>
            {props.view.templateModel &&
              <DsTemplateSelect
                open={templateDialog.value.open}
                templateForm={props.view.templateModel}
                itemKeyName={props.view.headerKey || 'name'}
                loading={templateDialog.value.loading}
                onClose={() => { templateDialogValue.value = props.view.templateModel?.default || {}; templateDialog.value.open = false }}
                value={templateDialogValue.value}
                handleChange={(value: RecordValue) => {
                  templateDialogValue.value = value
                }}
                onSelect={async ({ product_id, order_id }: any) => {
                  if (Object.keys(relations).includes(props.view.collection)) {
                    const templateRecords = relations[props.view.collection]
                    duplicateRow({ ...(Object.values(templateRecords).find((value: any) => value.id === product_id)), order_id: order_id })
                  }
                }}
                relations={relations}
              />}

            <DsCreateDialog
              open={dialogOpen.value}
              name={props.view.collection}
              title={props.view.createButtonLabel}
              model={props.view.model}
              value={editedValue.value}
              handleChange={handleChange}
              handleCreate={handleCreate}
              relations={relations}
              onClose={() => (dialogOpen.value = false)}
              onClosed={() => {
                editedValue.value = props.defaultValue()
              }}
            />


          </div>}
        </div>
        <DsUpdateDrawer
          ref={drawerRef}
          open={drawerOpen.value}
          name={props.view.collection}
          title={props.view.title}
          model={props.view.model}
          workflow={props.view.workflow}
          history={props.view.history}
          value={editedValue.value}
          relations={relations}
          headerKey={props.view.headerKey}
          handleChange={handleChange}
          handleUpdate={handleUpdate}
          handleDelete={handleDelete}
          onClose={() => {
            drawerOpen.value = false
          }}
          onClosed={() => {
            editedValue.value = props.defaultValue()
            drawerRef.value?.setDisabled(true)
          }}
          onCancel={() => resetRecord()}
          onAction={(taskName: string, actionName?: string, payload?: RecordValue) => {
            try {
              executeAction(taskName, actionName, payload)
            } catch (e) {
              showMessage('Error', 'error')
            }
          }}
        />
        {!props.view.groupByKey ? <>
          {tableIsEmpty.value && !loading.value ? '' : <div style="display: flex;gap: 0.5em;margin-bottom: 2em;">
            <ElInput
              style="flex: 1 1 0%;"
              placeholder={`Search ${props.view.title}...`}
              prefix-icon={Search}
              size="small"
              modelValue={searchTerms.value}
              onInput={((value: string) => searchTerms.value = value)}
            ></ElInput>
            <FilterBuilder
              options={filterOptions.value}
              collection={props.view.collection}
              value={filters.value}
              onInput={(value: any) => filters.value = value}
              onApply={() => refreshData(filters.value)}
              loading={loading.value}
            />
            <DsExport
              model={props.view.model}
              collection={props.view.collection}
              handleExport={(exportParams: exportParams) => handleExport(exportParams, filters.value)}
            />
          </div>}
          <DsTable
            loading={loading.value}
            data={records.value}
            columns={props.view.columns}
            onRowClick={(row: RecordValue) => {
              editedValue.value = { ...row }
              drawerOpen.value = true
            }}
            tableIsEmpty={tableIsEmpty.value}
            searchTerms={searchTerms.value}
            model={props.view.model}
            rowKey={props.view.rowKey}
            relations={relations}
            mainAction={props.view.mainAction}
            secondaryActions={secondaryActions.value}
            title={props.view.title}
            collection={props.view.collection}
            handleExport={handleExport}

          /></> : <DsTableGroupBy
          loading={loading.value}
          data={records.value}
          columns={props.view.columns}
          onRowClick={(row: RecordValue) => {
            editedValue.value = { ...row }
            drawerOpen.value = true
          }}
          rowKey={props.view.rowKey}
          relations={relations}
          mainAction={props.view.mainAction}
          secondaryActions={secondaryActions.value}
          groupByKey={props.view.groupByKey}
        />
        }
      </>
    )
  },
})
