import { defineComponent, onMounted, ref, Ref, PropType, watch, computed } from 'vue'
import Draggable from 'vuedraggable'

import {
  ElForm,
  ElFormItem,
  ElInput,
  ElSelect,
  ElOption,
  ElCollapse,
  ElCollapseItem,
  ElButton,
  ElCheckbox,
  ElDropdown,
  ElDropdownItem,
  ElDropdownMenu,
  ElDatePicker,
} from 'element-plus'
import { MoreFilled } from '@element-plus/icons'

import DsUpload from './DsUpload'
import { showMessage } from '@/utils/messages'

interface Option {
  value: string | number | boolean
  label: string | number
  disabled?: boolean
}

export interface BaseInput {
  type: 'string' | 'uuid' | 'number' | 'boolean' | 'date'
  model?: string
  label: string
  enum?: Option[]
  allowCreate?: boolean
  format?: 'email' | 'password'
  required?: boolean
  multiple?: boolean
  onlyOnCreate?: boolean
  clearable?: boolean
  disabled?: boolean
  prefix?: string | Reference
  suffix?: string | Reference
  filter?: (...args: any) => boolean
  optionLabel?: (...args: any) => string
}

export interface FileInput {
  type: 'file'
  label: string
  required?: boolean
  multiple?: boolean
  onlyOnCreate?: boolean
}

export interface ObjectInput {
  type: 'object'
  propertiesLayout?: 'form' | 'list' | 'section'
  properties: { [key: string]: Model }
  required?: boolean
  label?: string
  indent?: boolean
  onlyOnCreate?: boolean
}

export interface ArrayInput {
  type: 'array'
  propertiesLayout?: 'form' | 'list' | 'section'
  items: Model
  label?: string
  itemKeyName?: string
  required?: boolean
  sortable?: boolean
  onlyOnCreate?: boolean
}

// export interface RefInput {
//   type:  'reference'
//   label: string
//   required?: boolean
//   multiple?: boolean
//   onlyOnCreate?: boolean
// }

export type Model = BaseInput | ObjectInput | ArrayInput | FileInput

// export interface Input {
//   type: 'array' | 'object' | 'string' | 'uuid' | 'number'
//   items?: Input
//   properties?: Model
//   model?: string
//   label?: string
//   enum?: Option[]
//   multiple?: boolean
//   required?: boolean
//   format?: 'email'
// }

// export interface Model {
//   [key: string]: Input
// }
export class Reference {
  scope: 'root' | 'local'
  key: string
  relationModel: string | undefined
  relationValueKey: string | undefined

  constructor(scope: 'root' | 'local', key: string, relationModel: string | undefined = undefined, relationValueKey: string | undefined = undefined) {
    this.scope = scope
    this.key = key
    if (relationValueKey) {
      this.relationModel = relationModel
      this.relationValueKey = relationValueKey
    }
  }
}

const RenderInput = defineComponent({
  name: 'RenderInput',
  props: {
    model: { type: Object as PropType<BaseInput>, required: true },
    inputKey: { type: String, required: false },
    value: {
      type: [String, Number, Boolean] as PropType<
        string | number | boolean | undefined
      >,
      required: false,
    },
    scopedValues: { type: Object as PropType<{ [key: string]: any }> },
    relations: { type: Object as PropType<Record<string, any>>, required: true },
    handleChange: {
      type: Function as PropType<(value: any) => void>,
      required: true,
    },
    disabled: { type: Boolean, required: false, default: false },
  },
  setup(props) {
    const options = ref<Option[]>([])
    let component: () => JSX.Element

    watch(() => ({ ...props.relations }), () => {
      if (props.model.model && props.relations[props.model.model]) {
        let data = Object.values(props.relations[props.model.model])
        data = data.filter((value: any) => props.model.filter ? props.model.filter(value, props.relations) : data)
        options.value = data.map((v: any) => ({
          value: v.id,
          label: props.model.optionLabel ? props.model.optionLabel(v) : v.name,
        }))
      }
    })


    if (
      (props.model.type === 'string' && props.model.enum !== undefined) ||
      (props.model.type === 'uuid' && props.model.model !== undefined)
    ) {
      component = () => (
        <ElSelect
          style="width: 100%"
          modelValue={props.value}
          onChange={(value) => props.handleChange(value === '' ? null : value)}
          placeholder={!props.disabled ? 'Select' : ' '}
          filterable
          clearable={props.model.clearable}
          disabled={props.model.disabled}
          multiple={props.model.multiple}
          allowCreate={props.model.allowCreate}
        >
          {options.value.map((v) => (
            <ElOption key={`key_${v.value}`} value={v.value} label={v.label} disabled={v.disabled} />
          ))}
        </ElSelect>
      )
    } else if (props.model.type === 'boolean') {
      component = () => (
        <ElCheckbox
          disabled={props.model.disabled}
          modelValue={props.value}
          onChange={(value) => props.handleChange(value)}
        ></ElCheckbox>
      )
    } else if (props.model.type === 'date') {
      component = () => (
        <ElDatePicker
          style="width:100%"
          disabled={props.model.disabled}
          modelValue={typeof (props.value) === 'string' ? props.value : ''}
          {...{
            'onUpdate:modelValue': (value) => {
              props.handleChange(new Date(value).toISOString().split('T')[0])
            }
          }}
          type='date'
          format="YYYY-MM-DD"
        />
      )
    } else {
      const getReferenceValue = (value: Reference) => {
        if (value.scope === 'local' && props.scopedValues) {
          return props.scopedValues[value.key]
        } else if (value.scope === 'root') {
          return props.relations['currentValue'][value.key]
        }
      }
      const computedModel = computed<{ [key: string]: any }>(() =>
        Object.entries(props.model).reduce((previousValue: { [key: string]: any }, [key, value]) => {
          if (value instanceof Reference) {
            if (value.relationModel && value.relationValueKey) {
              const id = getReferenceValue(value)
              if (id) {
                previousValue[key] = props.relations[value.relationModel][id][value.relationValueKey]
              }
            } else {
              previousValue[key] = getReferenceValue(value)
            }
          } else {
            previousValue[key] = value
          }
          return previousValue
        }, {})
      )

      component = () => (
        <ElInput
          disabled={computedModel.value.disabled}
          modelValue={props.value as any}
          onInput={(value) => props.handleChange(value)}
          type={computedModel.value.format}
          v-slots={
            {
              prepend: computedModel.value.prefix,
              append: computedModel.value.suffix
            }
          }
        />
      )
    }

    onMounted(async () => {
      if (Array.isArray(props.model.enum)) {
        options.value = props.model.enum
      } else if (props.model.model !== undefined && props.relations[props.model.model]) {
        let data = Object.values(props.relations[props.model.model])
        data = data.filter((value: any) => props.model.filter ? props.model.filter(value, props.relations) : true)
        options.value = data.map((v: any) => ({
          value: v.id,
          label: props.model.optionLabel ? props.model.optionLabel(v) : v.name,
        }))
      }
    })

    const rules: any[] = []
    if (props.model.required) {
      rules.push({
        required: true,
        message: `Please input ${props.model.label}`,
        trigger: 'blur',
      })
    }

    if (props.model.format === 'email') {
      rules.push({
        type: 'email',
        message: 'Please input a correct email address',
        trigger: 'blur',
      })
    }

    return () => (
      <ElFormItem prop={props.inputKey} label={props.model.label} rules={rules}>
        {component()}
      </ElFormItem>
    )
  },
})

const RenderFile = defineComponent({
  name: 'RenderFile',
  props: {
    document: { type: String, required: true },
    documentId: { type: String, required: true },
    model: { type: Object as PropType<FileInput>, required: true },
    inputKey: { type: String, required: true },
    value: {
      type: String,
      required: false,
    },
    disabled: { type: Boolean, required: false, default: false },
  },
  setup(props) {
    return () => (
      <ElFormItem prop={props.inputKey} label={props.model.label}>
        <DsUpload
          document={props.document}
          documentId={props.documentId}
          field={props.inputKey}
          useFilename
          disabled={props.disabled}
          multiple={props.model.multiple}
        />
      </ElFormItem>
    )
  },
})

const RenderArray = defineComponent({
  components: { Draggable },
  name: 'RenderArray',
  props: {
    document: { type: String, required: true },
    documentId: { type: String, required: true },
    model: { type: Object as PropType<ArrayInput>, required: true },
    inputKey: { type: String, required: false },
    relations: { type: Object as PropType<Record<string, any>>, required: true },
    value: {
      type: [Array] as PropType<unknown[] | undefined>,
      required: false,
    },
    handleChange: {
      type: Function as PropType<(value: any) => void>,
      required: true,
    },
    disabled: { type: Boolean, required: false, default: false },
  },
  setup(props) {
    const handleChange = (index: number, value: any) => {
      const array_ = [...(props.value || [])]
      array_[index] = value
      props.handleChange(array_)
    }

    const getItemName = (v: any, index: number) => {
      const items = props.model.items
      if (props.relations && props.model.itemKeyName && items.type === 'object' && items.properties[props.model.itemKeyName]) {
        const subModel = items.properties[props.model.itemKeyName]
        if ('model' in subModel && subModel.model) {
          if (v[props.model.itemKeyName]) {
            const relationValue = props.relations[subModel.model][v[props.model.itemKeyName]]
            return (relationValue.label || relationValue.name)
          }
        }
      }
      if (props.model.itemKeyName) {
        return v[props.model.itemKeyName] ||
          `${props.model.items.label} ${index + 1}`
      }
      return `${props.model.items.label} ${index + 1}`
    }
    const item = ref(undefined)

    const renderContent = () => (
      <>
        {props.value != undefined && props.value.length !== 0 && (
          <ElCollapse
            modelValue={item.value}
            onChange={(value) => (item.value = value)}
            accordion
          >
            <Draggable
              modelValue={props.value}

              {...{
                handle: `.handle-${props.inputKey?.replaceAll('.', '')}`,
                'onUpdate:modelValue': (value: any) => props.handleChange(value)
              }}
              componentData={
                {
                  animation: 1000,
                  onChoose: (evt: any) => { evt.stopPropagation(), item.value = undefined },
                }
              }
              v-slots={{
                item: (value: any) => {
                  const v = value.element
                  const index = value.index
                  return (
                    <ElCollapseItem
                      name={index}
                      v-slots={{
                        title: () => (
                          <span style="width: 100%;padding-right: 20px;display: inline-flex;align-items: center;">
                            {(!props.disabled && (<svg class={`handle handle-${props.inputKey?.replaceAll('.', '')}`} viewBox="0 0 24 24" style="width:20px">
                              <path
                                fill="#909399"
                                d="M11 18c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zm-2-8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
                              ></path>
                            </svg>))}
                            <span>{getItemName(v, index)}</span>
                            {(!props.disabled && (
                              <p style="margin-left:auto" onClick={(e) => e.stopPropagation()}>
                                <ElDropdown
                                  trigger="click"
                                  size="small"
                                  style='vertical-align: middle;'
                                  onCommand={(command) => {
                                    if (command === "delete") {
                                      const array_ = [...(props.value || [])]
                                      array_.splice(index, 1)
                                      props.handleChange(array_)
                                    }
                                  }
                                  }
                                  v-slots={{
                                    default: () => (
                                      <span style="display:flex"><MoreFilled style="transform: rotate(90deg);width:14px;color:#909399;" /></span>
                                    ),
                                    dropdown: () => (
                                      <ElDropdownMenu>
                                        <ElDropdownItem command="delete">
                                          Delete
                                        </ElDropdownItem>
                                      </ElDropdownMenu>
                                    ),
                                  }}
                                /></p>
                            ))}
                          </span>
                        )
                      }}
                    >
                      {item.value === index && (
                        <RenderModel
                          document={props.document}
                          documentId={props.documentId}
                          key={index}
                          inputKey={`${props.inputKey || ''}.${index}`}
                          model={props.model.items}
                          relations={props.relations}
                          value={v as any}
                          handleChange={(value) => handleChange(index, value)}
                          disabled={props.disabled}
                        />
                      )}
                    </ElCollapseItem>
                  )
                }
              }
              }
            />
          </ElCollapse>)}
        {!props.disabled && (
          <ElButton
            style={{
              marginTop:
                !props.value || props.value.length === 0 ? null : '1em',
            }}
            onClick={() => handleChange(props.value?.length || 0, {})}
          >
            Add
          </ElButton>
        )}
      </>
    )
    return () => {
      if (
        props.model.propertiesLayout === undefined ||
        props.model.propertiesLayout === 'form'
      ) {
        return (
          <ElFormItem label={props.model.label}>{renderContent()}</ElFormItem>
        )
      } else {
        return (
          <div style="margin: 2em 0;">
            <h2 style="font-size: 14px;">{props.model.label}</h2>
            {renderContent()}
          </div>
        )
      }
    }
  },
})

const RenderObject = defineComponent({
  name: 'RenderObject',
  props: {
    document: { type: String, required: true },
    documentId: { type: String, required: true },
    model: { type: Object as PropType<ObjectInput>, required: true },
    inputKey: { type: String, required: false },
    value: { type: Object, required: true },
    handleChange: {
      type: Function as PropType<(value: any) => void>,
      required: true,
    },
    relations: { type: Object as PropType<Record<string, any>>, required: true },
    disabled: { type: Boolean, required: false, default: false },
  },
  setup(props) {
    const handleChange = (key: string, value: any) => {
      props.handleChange({ ...Object.assign({}, props.value), [key]: value })
    }
    const renderContent = (key: string, model: Model) => {
      if (model.type === 'array' && model.items.type === 'string') {
        return (
          <RenderInput
            key={`${props.inputKey || ''}.${key}`}
            model={model.items}
            inputKey={`${props.inputKey || ''}.${key}`}
            value={props.value[key]}
            scopedValues={props.value}
            relations={props.relations}
            handleChange={(value) => handleChange(key, value)}
            disabled={props.disabled}
          />
        )
      } else if (model.type === 'file') {
        return (
          <RenderFile
            document={props.document}
            documentId={props.documentId}
            key={`${props.inputKey || ''}.${key}`}
            model={model}
            inputKey={`${props.inputKey || ''}.${key}`}
            value={props.value[key]}
            disabled={props.disabled}
          />
        )
      } else if (
        model.type === 'string' ||
        model.type === 'number' ||
        model.type === 'boolean' ||
        model.type === 'uuid' ||
        model.type === 'date'
      ) {
        return (
          <RenderInput
            key={`${props.inputKey || ''}.${key}`}
            model={model}
            inputKey={`${props.inputKey || ''}.${key}`}
            value={props.value[key]}
            scopedValues={props.value}
            relations={props.relations}
            handleChange={(value) => handleChange(key, value)}
            disabled={props.disabled}
          />
        )
      } else if (
        model.type === 'object' &&
        (typeof props.value[key] === 'object' || !props.value[key])
      ) {
        return (
          <RenderModel
            document={props.document}
            documentId={props.documentId}
            model={model}
            inputKey={`${props.inputKey || ''}.${key}`}
            value={props.value[key]}
            relations={props.relations}
            handleChange={(value) => handleChange(key, value)}
            disabled={props.disabled}
          />
        )
      } else if (
        model.type === 'array' &&
        typeof props.value[key] !== 'string' &&
        typeof props.value[key] !== 'number' &&
        (Array.isArray(props.value[key]) || !props.value[key])
      ) {
        return (
          <RenderArray
            document={props.document}
            documentId={props.documentId}
            key={`${props.inputKey || ''}.${key}`}
            model={model}
            relations={props.relations}
            inputKey={`${props.inputKey || ''}.${key}`}
            value={props.value[key]}
            handleChange={(value) => handleChange(key, value)}
            disabled={props.disabled}
          />
        )
      }
    }

    return () => {
      if (
        props.model.propertiesLayout === undefined ||
        props.model.propertiesLayout === 'form'
      ) {
        return Object.entries(props.model.properties).map(([key, model]) =>
          renderContent(key, model)
        )
      } else {
        return (
          <div style="margin: 2em 0;">
            <h2 style="font-size: 14px;">{props.model.label}</h2>
            {Object.entries(props.model.properties).map(([key, model]) =>
              renderContent(key, model)
            )}
          </div>
        )
      }
    }
  },
})

const RenderModel = defineComponent({
  name: 'RenderModel',
  props: {
    document: { type: String, required: true },
    documentId: { type: String, required: true },
    model: { type: Object as PropType<Model>, required: true },
    value: { type: [Number, String, Object, Array], required: true },
    inputKey: { type: String, required: false },
    relations: { type: Object as PropType<Record<string, any>>, required: true },
    handleChange: {
      type: Function as PropType<(value: any) => void>,
      required: true,
    },
    disabled: { type: Boolean, required: false, default: false },
  },
  setup(props) {
    return () => {
      if (
        (props.model.type === 'string' ||
          props.model.type === 'number' ||
          props.model.type === 'uuid') &&
        (typeof props.value === 'string' || !props.value)
      ) {
        return (
          <RenderInput
            model={props.model}
            inputKey={props.inputKey}
            value={props.value}
            handleChange={props.handleChange}
            relations={props.relations}
            disabled={props.disabled}
          />
        )
      } else if (
        props.model.type === 'object' &&
        typeof props.value === 'object' &&
        (!Array.isArray(props.value) || !props.value)
      ) {
        return (
          <RenderObject
            document={props.document}
            documentId={props.documentId}
            model={props.model}
            inputKey={props.inputKey}
            value={props.value}
            relations={props.relations}
            handleChange={props.handleChange}
            disabled={props.disabled}
          />
        )
      } else if (
        props.model.type === 'array' &&
        typeof props.value !== 'string' &&
        typeof props.value !== 'number' &&
        (Array.isArray(props.value) || !props.value)
      ) {
        return (
          <RenderArray
            document={props.document}
            documentId={props.documentId}
            model={props.model}
            inputKey={props.inputKey}
            value={props.value}
            relations={props.relations}
            handleChange={props.handleChange}
            disabled={props.disabled}
          />
        )
      }
    }
  },
})

export default defineComponent({
  name: 'DsForm',
  components: {
    ElForm,
    ElFormItem,
    ElInput,
    ElDropdown,
    ElDropdownItem,
    ElDropdownMenu, MoreFilled
  },
  props: {
    document: { type: String, required: true },
    model: { type: Object as PropType<Model>, required: true },
    value: { type: Object as PropType<Record<string, any>>, required: true },
    handleChange: {
      type: Function as PropType<(value: any) => void>,
      required: true,
    },
    relations: { type: Object as PropType<Record<string, any>>, required: true },
    disabled: { type: Boolean, required: false, default: false },
    createForm: { type: Boolean, required: false, default: true },
    labelPosition: { type: String, required: false },
  },
  setup(props, { expose }) {
    const refForm: Ref<HTMLFormElement | null> = ref(null)

    const validate = async (): Promise<boolean> => {
      try {
        await refForm.value?.validate()
        return true
      } catch (error) {
        showMessage('Some fields are missing or invalid', 'warning')
        return false
      }
    }

    const clearValidate = () => {
      refForm.value?.clearValidate()
    }

    const formatModel = (model: Model) => {
      if (!('properties' in model)) return model
      const formatedModel = { ...model, properties: {} }
      const modelProperties = model.properties
      for (const [key, field] of Object.entries(modelProperties)) {
        if ((!field?.onlyOnCreate && !props.createForm) || props.createForm) {
          formatedModel.properties = {
            ...formatedModel.properties,
            [key]: formatModel(field),
          }
        }
      }
      return formatedModel
    }

    expose({ validate, clearValidate })
    return () => (
      <ElForm
        size="mini"
        ref={refForm}
        labelWidth="150px"
        labelPosition={props.labelPosition || 'left'}
        disabled={props.disabled}
        hideRequiredAsterisk={props.disabled}
        model={props.value}
      >
        <RenderModel
          document={props.document}
          documentId={props.value.id}
          model={formatModel(props.model)}
          relations={props.relations}
          value={props.value}
          handleChange={props.handleChange}
          disabled={props.disabled}
        />
      </ElForm>
    )
  },
})
