import { defineComponent, PropType } from 'vue'

import { ElDrawer, ElButton } from 'element-plus'
import DsTag from './DsTag'
import { formatDate } from '@/utils/datetimes'

export default defineComponent({
  name: 'DsDrawer',
  components: { ElDrawer, ElButton, DsTag },
  props: {
    open: { type: Boolean, required: true },
    width: { type: String, default: '550px' },
    onClose: { type: Function as PropType<() => void> },
    onClosed: { type: Function as PropType<() => void>, required: false },
  },
  setup(props, { slots }) {
    return () => (
      <ElDrawer
        modelValue={props.open}
        onClose={props.onClose}
        onClosed={props.onClosed}
        destroyOnClose
        size={props.width}
        withHeader={false}
      >
        {slots.header && <header>{slots.header()}</header>}
        {slots.default && slots.default()}
      </ElDrawer>
    )
  },
})
