import { defineComponent, PropType, computed, ref } from 'vue'
import { cloneDeep } from 'lodash'
import {
  ElDropdown, ElDropdownMenu, ElDropdownItem, ElPopover, ElForm, ElTag, ElButton, ElCheckbox, ElOption, ElSelect, ElFormItem, ElInput
} from 'element-plus'
import { Delete as DeleteIcon, Close as CloseIcon, Check as CheckIcon } from '@element-plus/icons'
import { showMessage } from '@/utils/messages'
import './DsExport.css'
import { useStore } from '@/store'
import { Model } from '@/components/DsForm'

import { Preference } from '@/store/preferences/preferences'

export interface exportParams {
  format: 'excel' | 'csv'
  apply_filter: boolean
  include_attachments: boolean
  type: 'ALL' | 'CUSTOM'
  columns: string[]
}

export default defineComponent({
  name: 'DsExport',
  components: { ElDropdown, ElDropdownMenu, ElDropdownItem, ElPopover, ElForm, ElTag, ElButton, ElCheckbox, ElOption, ElSelect, ElFormItem, ElInput },
  props: {
    title: { type: String, required: false },
    collection: { type: String, required: true },
    columns: {
      type: Object as PropType<{ [key: string]: string }>,
      required: false,
      default: () => { return {} }
    },
    model: {
      type: Object as PropType<Model>, required: true
    },
    exportData: { type: Function, required: false },
    defaultFormat: { type: String as PropType<'excel' | 'csv'>, default: 'excel' },
    options: { type: Array as PropType<{ key: string, label: string }[]> },
    formatOptions: { type: Array, default: () => ['excel', 'csv'] },
    showIncludeAttachments: { type: Boolean, required: false, default: true },
    handleExport: { type: Function as PropType<(exportParams: exportParams) => void>, required: true },
  },
  setup(props) {
    const exportName = ref('')
    const showInput = ref(false)
    const loading = ref(false)


    const columns = computed<{ [key: string]: string }>(() =>
      props.model.type === 'object' ? Object.entries(props.model.properties).reduce((prev: { [key: string]: string }, [key, model]) => { prev[key] = model.label || key; return prev }, {}) : {}
    )



    const exportParams = ref<exportParams>({
      format: props.defaultFormat === 'excel' || props.defaultFormat === 'csv' ? props.defaultFormat : 'excel',
      apply_filter: true,
      include_attachments: false,
      type: 'ALL',
      columns: []
    })

    const store = useStore()
    const exportViews = computed<Array<Preference> | null>(() => store.getters.exportsPreferences)

    const handleCreateView = async (name: string, exportView: any) => {
      try {
        await store.dispatch('createPreference', { name: name, collection: props.collection, type: 'export', value: exportView })
        showInput.value = false
        exportName.value = ''
        showMessage('Export is saved', 'success')
      } catch (error) {
        if (typeof (error) === 'string') showMessage(error, "error")
      }
    }

    const handleDeleteView = async (event: MouseEvent, id: string) => {
      event.stopPropagation()
      store.dispatch('deletePreference', id)
    }

    const handleSelectView = async (id: string) => {
      const view = await store.dispatch('getPreferenceById', id)
      exportParams.value = cloneDeep(view.value)
    }


    const handleExportTypeChange = (value: any) => {
      exportParams.value.type = value
      exportParams.value.columns = Object.keys(columns.value)
    }



    const handleChange = (colKey: string, checked: boolean) => {
      if (checked) {
        exportParams.value.columns.push(colKey)
      } else {
        const i = exportParams.value.columns.findIndex(c => c === colKey)
        exportParams.value.columns.splice(i, 1)
      }
    }

    return () => (
      <div>
        <ElDropdown
          size="small"
          split-button
          trigger="click"
          onCommand={handleSelectView}
          placement={"bottom"}
          v-slots={{
            default: () => (
              <ElPopover
                trigger='click'
                width="550px"
                v-slots={{
                  reference: () => (
                    <div
                      class="export-split-button"
                      style="margin: -9px -15px; padding: 9px 15px;"
                    >
                      Export
                    </div>
                  )
                }}>
                <div style="margin: -12px;">
                  <div style="display: flex; flex-direction: column;">
                    <div style="max-height: 400px; overflow: auto; padding: 2em;">
                      <h1
                        style="margin-top: 0; margin-bottom: 1em; font-weight: 400; line-height: 24px; font-size: 18px; color: #303133;"
                      >
                        {props.title || `Export ${props.collection} data`},
                      </h1>
                      <ElForm size="small" labelPosition="top">
                        <div style="display: flex; flex-direction: column; gap: 1em">
                          <ElCheckbox
                            label="Apply filters"
                            modelValue={exportParams.value.apply_filter}
                            onChange={(value: boolean) => exportParams.value.apply_filter = value}
                          />
                        </div>
                        <ElFormItem label="Format">
                          <ElSelect
                            modelValue={exportParams.value.format}
                            onChange={(value) => (exportParams.value.format = value)}
                            popperAppendToBody={false}
                          >
                            {props.formatOptions.includes('excel') &&
                              <ElOption
                                label="Excel (.xlsx)"
                                value="excel"
                              />}
                            {props.formatOptions.includes('csv') &&
                              <ElOption
                                label="Format CSV (.csv)"
                                value="csv"
                              />}

                          </ElSelect>
                        </ElFormItem>
                        {columns.value !== undefined && <div >
                          <ElFormItem label="Columns">
                            <ElSelect
                              modelValue={exportParams.value.type}
                              onChange={handleExportTypeChange}
                              popperAppendToBody={false}
                              collapse-tags
                            >
                              <ElOption label="All" value="ALL" />
                              <ElOption label="Custom" value="CUSTOM" />
                            </ElSelect>
                          </ElFormItem>
                          <ElFormItem>
                            <div
                              style="display: grid; grid-template-columns: repeat(auto-fill,minmax(200px, 1fr));"
                            >
                              {Object.entries(columns.value).map(([colKey, colName]) => <ElCheckbox
                                disabled={exportParams.value.type === 'ALL'}
                                modelValue={exportParams.value.type === 'ALL' ? true : exportParams.value.columns.findIndex(col => col === colKey) !== -1}
                                onChange={(checked) => handleChange(colKey, checked)}
                                key={colKey}
                                label={colName}
                              />)}
                            </div>
                          </ElFormItem>
                        </div>}
                      </ElForm>
                    </div>
                    <div style="padding: 1em; border-top: 0.5px solid #DCDFE6">
                      <div style="float: right; display: flex; gap: 1em;">
                        <div style="display: flex; gap: 1em;">
                          {!showInput.value ?
                            <ElButton
                              size="small"
                              type="text"
                              onClick={() => showInput.value = true}
                            >
                              Save configuration
                            </ElButton> :
                            <div
                              style="display: flex; align-items: center;"
                            >
                              <ElInput
                                size="mini"
                                placeholder="Export name..."
                                modelValue={exportName.value}
                                onInput={(value) => (exportName.value = value)}
                              />
                              <ElButton
                                style="margin-left: 1em;"
                                size="small"
                                type="text"
                                onClick={() => {
                                  showInput.value = false
                                  exportName.value = ''
                                }}
                                icon={CloseIcon}
                              />
                              <ElButton
                                style="margin-right: 1em;"
                                size="small"
                                type="text"
                                onClick={() => handleCreateView(exportName.value, exportParams.value)}
                                icon={CheckIcon}
                              />
                            </div>}
                        </div>
                        <ElButton
                          size="small"
                          type="primary"
                          onClick={async () => {
                            loading.value = true
                            await props.handleExport(exportParams.value)
                            loading.value = false
                          }}
                          loading={loading.value}
                        >
                          Export
                        </ElButton>
                      </div>
                    </div>
                  </div>
                </div>
              </ElPopover >
            ),
            dropdown: () => (
              <ElDropdownMenu>
                {exportViews.value && exportViews.value.length !== 0 ?
                  (
                    exportViews.value && exportViews.value.map((view: Preference) => <ElDropdownItem
                      key={view.id}
                      command={view.id}
                    >
                      <div class="view-item">
                        <span class="view-item-name">
                          <ElTag size="mini">{view.name}</ElTag>
                        </span>
                        <div class="view-item-delete-container">
                          <ElButton
                            class="view-item-delete-button"
                            type="text"
                            size="small"
                            onClick={(event) => handleDeleteView(event, view.id || '')}
                            icon={DeleteIcon}
                          />
                        </div>
                      </div>
                    </ElDropdownItem>)) : (
                    <ElDropdownItem disabled>
                      No saved export configuration
                    </ElDropdownItem>)}
              </ElDropdownMenu>)
          }}
        >
        </ElDropdown >
      </div >)
  },
})
