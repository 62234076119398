import { defineComponent, PropType, computed, ref, onMounted, watch } from 'vue'
import { orderBy } from 'lodash'

import {
  ElInput,
  ElSelect,
  ElButton,
  ElDropdown,
  ElDropdownItem,
  ElDropdownMenu,
  ElButtonGroup,
} from 'element-plus'
import { MoreFilled } from '@element-plus/icons'

import DsTag from './DsTag'
import DsPagination from './DsPagination'
import './DsTable.css'
import DsSortIcon from '@/components/DsSortIcon'
import FilterBuilder from '@/components/FilterBuilder'
import DsExport, { exportParams } from '@/components/DsExport'


import { Model } from '@/components/DsForm'

import {
  Search,
  Loading as LoadingIcon
} from '@element-plus/icons'

export interface Column {
  key: string
  header?: string
  sortable?: boolean
  type?: 'tag'
  defaultSort?: 'asc' | 'desc'
  icon?: boolean
  formatter?: (value: unknown, relations: unknown) => string | JSX.Element
}

type Row = Record<string, any>

const isEmpty = (value: string | undefined | null | unknown[]): boolean => {
  return (
    value === '' || value === undefined || value === null || value.length === 0
  )
}

const getColumnValue = (row: Row, key: string) => {
  const path = key.split('.')
  if (path.length === 1) {
    return row[key]
  } else {
    let value = row
    for (const key of path) {
      value = value[key]
    }
    return value
  }
}
export interface Action {
  icon: any
  label: string
  callback: (row: Row) => void | Promise<void>
}

export default (<T extends Row>() =>
  defineComponent({
    name: 'DsTable',
    components: {
      DsTag,
      DsPagination,
      ElButtonGroup,
      ElDropdown,
      ElDropdownItem,
      ElDropdownMenu,
      DsSortIcon,
      ElInput,
      ElSelect,
      Search,
      FilterBuilder,
      DsExport,
    },
    props: {
      data: { type: Array as PropType<T[]>, required: true },
      columns: { type: Array as PropType<Column[]>, required: true },
      mainAction: { type: Object as PropType<Action>, required: false },
      secondaryActions: { type: Array as PropType<Action[]>, required: false },
      onRowClick: {
        type: Function as PropType<
          (row: T, column: Column, evt: MouseEvent) => void
        >,
      },
      tableIsEmpty: { type: Boolean, required: true },
      searchTerms: {
        type: String, required: false
      },
      rowKey: { type: String, required: false, default: 'id' },
      relations: { type: Object },
      loading: { type: Boolean, required: false, default: false },
      collection: { type: String, required: true },
      title: { type: String, required: true },
      model: { type: Object as PropType<Model>, required: true },
      handleExport: {
        type: Function as PropType<
          (exportParams: exportParams, filters: any | null) => void
        >, required: true
      },
    },
    setup(props) {
      const currentPage = ref(0)
      const minPageLength = 25
      const pageLength = ref(minPageLength)
      const sortDirection = ref<'asc' | 'desc' | null>(null)
      const sortColumn = ref<string | null>(null)
      const search = ref<string | null>(null)
      const mainActionLoading = ref(null)

      const dataLength = computed(() => rows.value.length)

      onMounted(() => {
        props.columns.map((c) => {
          if (c.defaultSort) {
            sortColumn.value = c.key
            sortDirection.value = c.defaultSort
          }
        })
      })


      const rows = computed(() => {
        let data = props.searchTerms ? props.data.filter((row: any) => JSON.stringify(row).toLowerCase().includes(props.searchTerms!.toLowerCase())) : props.data

        if (sortColumn.value) {
          data = orderBy(
            data,
            sortColumn.value || undefined,
            sortDirection.value || undefined
          )
        }
        return data
      }
      )


      const pageData = computed(() =>
        rows.value.slice(
          currentPage.value * pageLength.value,
          (currentPage.value + 1) * pageLength.value
        )
      )

      const renderPage = () => pageData.value.map((row) => (
        <tr key={row[props.rowKey]}>
          {props.columns.map((c) => {
            const rowValue = getColumnValue(row, c.key)
            return (
              <td
                key={`${row[props.rowKey]}-${c.key}`}
                class={c.icon ? 'icon-col' : null}
                onClick={(event) =>
                  props.onRowClick && props.onRowClick(row, c, event)
                }
              >
                {Array.isArray(rowValue)
                  ? rowValue.map((value: any) => renderValue(c, value))
                  : renderValue(c, rowValue)}
              </td>
            )
          })}
          {/* A transformer en Composant */}
          {showActionsColumn.value && (
            <td style="text-align: right;padding: 0;min-width: 140px">
              <ElButtonGroup>
                {props.mainAction ? (
                  <ElButton
                    size="small"
                    onClick={async () => {
                      mainActionLoading.value = row[props.rowKey]
                      const promise = props.mainAction && props.mainAction.callback(row) //need await before remove animation
                      if (promise) await promise
                      mainActionLoading.value = null
                    }}
                    icon={mainActionLoading.value === row[props.rowKey] ? <LoadingIcon style="animation: spin 2s linear infinite;" /> : props.mainAction.icon}
                  >
                    {props.mainAction && props.mainAction.label}
                  </ElButton>
                ) : null}
                {props.secondaryActions && (
                  <ElDropdown
                    trigger="click"
                    size="small"
                    onCommand={(index) =>
                      props.secondaryActions &&
                      props.secondaryActions[index]?.callback(row)
                    }
                    v-slots={{
                      default: () => (
                        <ElButton
                          style={
                            'width:28px;height:28px;padding:0px;' +
                            (props.mainAction
                              ? 'border-top-left-radius:0;border-bottom-left-radius:0'
                              : '')
                          }
                          size="small"
                          icon={
                            <MoreFilled style="transform: rotate(90deg);width:auto" />
                          }
                        ></ElButton>
                      ),
                      dropdown: () => (
                        <ElDropdownMenu>
                          {props.secondaryActions &&
                            props.secondaryActions.map((value, index) => (
                              <ElDropdownItem
                                command={index}
                                icon={value.icon}
                              >
                                {value.label}
                              </ElDropdownItem>
                            ))}
                        </ElDropdownMenu>
                      ),
                    }}
                  ></ElDropdown>
                )}
              </ElButtonGroup>
            </td>
          )}
        </tr>
      ))

      const showActionsColumn = computed(() => {
        return (
          props.mainAction !== undefined ||
          (props.secondaryActions !== undefined &&
            props.secondaryActions.length > 0)
        )
      })

      const renderValue = (c: any, value: any) =>
        c.type === 'tag' && !isEmpty(value) ? (
          <DsTag>{value}</DsTag>
        ) : c.formatter !== undefined ? (
          c.formatter(value, props.relations)
        ) : (
          value
        )



      const renderSkeleton = () => {
        return [...new Array(4)].map((value, index) => (
          <tr key={index}>
            {props.columns.map(() => (
              <td class="loading-cell">
                <span class="loading-skeleton" />
              </td>
            ))}
            {showActionsColumn.value && (
              <td class="loading-cell">
                <span class="loading-skeleton" />
              </td>
            )}
          </tr>
        ))
      }
      const colHeadClick = (colKey: string) => {
        if (sortColumn.value === colKey) {
          switch (sortDirection.value) {
            case null:
              sortDirection.value = 'asc'
              break
            case 'asc':
              sortDirection.value = 'desc'
              break
            case 'desc':
              sortColumn.value = null
              sortDirection.value = null
              break
          }
        } else {
          sortColumn.value = colKey
          sortDirection.value = 'asc'
        }
      }

      return () => (
        <div class="dsflow-table-container">
          {props.tableIsEmpty && !props.loading ?
            <div class='empty-state'>
              <img src={require('@/assets/empty-state.png')} style="height: 450px; margin-top: -150px;" alt="Empty State" ></img>
              <div style="margin-top: -100px">
                There is no data yet
              </div>
            </div>
            : <>
              <div style="overflow-x: auto; flex: 1;">
                <table class="dsflow-table">
                  <thead>
                    <tr>
                      {props.columns.map((c) => (
                        <th
                          key={c.key}
                          onClick={() => (c.sortable !== false ? colHeadClick(c.key) : null)}
                        >
                          <span style={{ cursor: c.sortable !== false ? 'pointer' : 'default' }}>{c.header}</span>
                          {c.sortable !== false && <DsSortIcon style='float:left;margin-right:3px' sortDirection={sortColumn.value === c.key ? sortDirection.value : null} />}
                        </th>
                      ))}
                      {showActionsColumn.value && <th></th>}
                    </tr>
                  </thead>
                  <tbody>
                    {props.loading && renderSkeleton()}
                    {!props.loading && renderPage()}
                  </tbody>
                </table>
              </div></>}
          {dataLength.value > minPageLength && (
            <DsPagination
              total={dataLength.value}
              page={currentPage.value}
              onPageChange={(value) => (currentPage.value = value)}
              pageLength={pageLength.value}
              onPageLengthChange={(value) => {
                pageLength.value = value
              }}
            />
          )}
        </div>
      )
    },
  }))()
